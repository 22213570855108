import axios from "axios";
//引入nprogress
import NProgress from "nprogress";
import "nprogress/nprogress.css"; //这个样式必须引入
import { Message } from "element-ui";
import router from "@/router";
// 简单配置
NProgress.inc(0.2);
NProgress.configure({ easing: "ease", speed: 500, showSpinner: false });
const CONFIG: any = {
  timeout: Number
};
CONFIG.timeout = 60000;
const http = axios.create(CONFIG);
// 这里是调用接口之前，做些啥子
http.interceptors.request.use(
  (config: any) => {
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);
interface AJAXdata<T> {
  records: any[];
  code: number;
  data?: T;
  msg: string;
  success: boolean;
}
// http.interceptors.response.use((res)=>{
//   return res
// })
async function request<T>(
  options: any,
  type?: string,
  haveId?: boolean
): Promise<AJAXdata<T>> {
  //开启进度条
  NProgress.start();
  const arg = {} as any;
  //如果需要请求文件类接口扩展的功能，类似石林、平舆的文件流
  if (options.responseType) {
    arg.responseType = options.responseType;
  }
  // if(!haveId){
  //   console.log(haveId,'haveId');
  // }
  // !haveId && (arg.headers = { 'tenantId ': '1' })
  arg.url = options.url;
  arg.method = options.method || "GET";
  if (type === "login") {
    arg.params = options.data;
    arg.headers = { Authorization: "Basic aGVhbHRoOmhlYWx0aA==", tenantId: -1 };
  } else {
    // 设置token
    const token: string | null = sessionStorage.getItem("Token");
    if (token) {
      const data = JSON.parse(token);
      const tenantId = { tenantId: sessionStorage.getItem("tenant_id") };
      const headers = {
        Authorization: data.access_token ? "Bearer " + data.access_token : ""
      };
      arg.headers = Object.assign({}, tenantId, headers);
    }
    // else if (options.url !== "auth/oauth/token" || options.url !== 'code') {
    //   //   window.location.href = '/'
    //   // }
    options.type === "params"
      ? (arg.params = options.data)
      : (arg.data = options.data);
  }
  return http(arg)
    .then((result: any) => {
      //关闭进度条
      NProgress.done();
      if (result.status === 200 || result.data.success) {
        return result.data ? result.data : result;
      } else {
        //如果错误则抛给下面的catch
        // console.log('result', result);
        Message.error({
          message: result.msg || result.message,
          center: true
        });
        return Promise.reject(result.data);
      }
    })
    .catch((err: any) => {
        // console.log('这是err', err.response);
      //关闭进度条
      // console.log(err.response.data,'errrr');
      const data = err.response.data;

      NProgress.done();
      const status = Number(data.code || data.status);
      const errMsg = data.message || data.msg;
      Message.error({
        message: errMsg,
        center: true
      });
      if (status === 401) {
        Message.warning({
          message: "登录失效，请重新登录！",
          center: true
        });
        // window.location.href = '/'
        router.push("/");

        // 暂时不做操作
      }
      //   if (status === 500 || status === 401) {
      //     // ("系统异常500,暂时不做操作！")
      //     Message.error({
      //       message: err.response.data.msg,
      //       center: true
      //     })
      //   }
      return data;
    });
}
export default request;
